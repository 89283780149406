import { useEffect, useState } from "react";
import { Title } from "../../../styles/global";
import MainTable from "../../../components/MainTable";
import { Container } from "./styles";
import useAutorizacaoDespesa from "../../../actions/cruds/Financeiro/useAutorizacaoDespesa";
import { formatarData, formatarReais } from "../../../utils/formatDados";
import { useAuth } from "../../../hooks/auth";
import getPermission from "../../../utils/getPermission";

const FinanceiroAutorizacaoDespesa: React.FC = () => {
  const { autorizacoesDespesas, getAutorizacoesDespesas } = useAutorizacaoDespesa();

  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const optionsSituacao = [
    { label: "Aguardando realização do pagamento", value: "aguardando_realizacao_pagamento" },
    { label: "Aguardando confirmação do pagamento", value: "aguardando_confirmacao_pagamento" },
    { label: "Concluído", value: "concluido" },
    { label: "Cancelado", value: "cancelado" },
  ];

  const {login} = useAuth();
  const financeiroPermission = getPermission(login,"Financeiro") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getAutorizacoesDespesas([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getAutorizacoesDespesas([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getAutorizacoesDespesas([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: autorizacoesDespesas.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        codigo: d.codigo,
        data: formatarData(d.created_at, false) ?? withoutData,
        instrumento: d.attest?.contractual_instrument?.numero ?? withoutData,
        favorecido: d.attest?.supplier?.nome_razao_social ?? withoutData,
        valor: formatarReais(d.invoice?.valor) ?? withoutData,
        situacao: d.situacao ? optionsSituacao.find(o => o.value == d?.situacao)?.label : withoutData,
        ativo: d.ativo,
      }));
    }
    return data;
  };

  const columnData = [
    { headerName: "Número da autorização", field: "codigo" },
    { headerName: "Data da solicitação", field: "data" },
    { headerName: "Instrumento", field: "instrumento" },
    { headerName: "Favorecido", field: "favorecido" },
    { headerName: "Valor", field: "valor" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Autorização de despesa</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          columns={columnData}
          data={organizeData(autorizacoesDespesas.data)}
          pagination={autorizacoesDespesas.pagination}
          permission={financeiroPermission}
          hideCreate={financeiroPermission == "Visitante"}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/financeiro/autorizacaodespesa/cadastrar"
          dropdown={{
            view: { linkTo: "/financeiro/autorizacaodespesa/visualizar" },
            realizarPagamentoDespesa: { linkTo: "/financeiro/autorizacaodespesa/realizarpagamento" },
            informarPagamentoDespesa: { linkTo: "/financeiro/autorizacaodespesa/informarpagamento" },
            cancelar: { linkTo: "expense_authorizations" },
            // desativar: { linkTo: "" },
          }}
        />
      </Container>
    </>
  );
};

export default FinanceiroAutorizacaoDespesa;
