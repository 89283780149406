import React, { useEffect, useState } from "react";
import { Title, Container } from "./styles";
import { CSidebar } from "../../../../components/Sidebar";
import Header from "../../../../components/Header";
import MainTable from "../../../../components/MainTable";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/auth";
import getPermission from "../../../../utils/getPermission";

const UnidadeRh: React.FC = () => {
  const [unidades, setUnidades] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const {login} = useAuth();
  const rhPermission = getPermission(login,"Recursos Humanos") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`units?busca_rapida=${searchValue}`)
        .then((response) => {
          setUnidades(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`units?page=${unidades.pagination.current_page + page}`)
      .then((response) => {
        setUnidades(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "Sigla", field: "sigla" },
    { headerName: "Unidade", field: "unidade" },
    { headerName: "Organização", field: "organizacao" },
    { headerName: "Telefone", field: "telefone" },
    { headerName: "Email", field: "email" },
    { headerName: "Situação", field: "ativo" },
  ];

  const organizeData = (data) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        sigla: d.sigla ?? withoutData,
        unidade: d.nome,
        organizacao: d.organization.nome,
        telefone: d.telefone ? d.telefone : withoutData,
        email: d.email ? d.email : withoutData,
        ativo: d.ativo,
      }));
    }

    return data;
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <CSidebar />
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Header />
        <Title color="#333">
          <span>Gestão de unidades</span>
        </Title>
        <Container color="#FFF">
          <MainTable
            onSearchChange={setSearchValue}
            data={organizeData(unidades.data)}
            pagination={unidades.pagination}
            permission={rhPermission}
            hideCreate={rhPermission == "Visitante"}
            handlePagination={handlePagination}
            hideFilter={true}
            registerLink="/rh/cadastros/unidade/cadastrar"
            columns={columnData}
            dropdown={{
              view: { linkTo: "/rh/cadastros/unidade/visualizar" },
              editar: { linkTo: "/rh/cadastros/unidade/editar" },
              desativar: { linkTo: "units" },
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default UnidadeRh;
