import { Container } from "./styles";
import { Data } from "./types";
import MainTable from "../../../components/MainTable";
import { useEffect, useState } from "react";
import { Title } from "../../../styles/global";
import usePoa from "../../../actions/cruds/usePoa";
import { useAuth } from "../../../hooks/auth";
import getPermission from "../../../utils/getPermission";

export const steps = [
  { label: "Identificação", value: 1 },
  { label: "Sub-ação", value: 2 }
];

const Poa: React.FC = () => {
  const { getPoas, poas } = usePoa();

  useEffect(() => {
    getPoas();
  }, []);

  const {login} = useAuth();
  const orçamentarioPermission = getPermission(login,"Orçamentário") ?? '';

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getPoas([{label: "page", value: poas.pagination.current_page + page}]);
  };

  const organizeData = (data: Data[]) => {
    if (typeof data === "object") {
      data = data.map((item) => {

        return {
          id: item.id,
          organizacao: item.pap.comittee.management_contract.organization.nome.nome,
          contrato: item.pap.comittee.management_contract.nome,
          comite: item.pap.comittee.nome,
          pap: `${item.pap.periodo_de} - ${item.pap.periodo_ate}`,
          poa: item.poa,
          ativo: item.ativo,
        };
      });
    }

    return data;
  };

  const columnData = [
    { headerName: "Organização", field: "organizacao" },
    { headerName: "Contrato de gestão", field: "contrato" },
    { headerName: "Comitê", field: "comite" },
    { headerName: "PAP", field: "pap" },
    { headerName: "POA", field: "poa" },
    { headerName: "Situação", field: "ativo" },
  ];

  return (
    <>
        <Title color="#333">
            <span>Gestão do POA</span>
        </Title>
        <Container color="#FFF">
            <MainTable
              data={organizeData(poas.data)}
              pagination={poas.pagination}
              permission={orçamentarioPermission}
              hideCreate={orçamentarioPermission == "Visitante"}
              handlePagination={handlePagination}
              hideFilter={true}
              registerLink="/orcamentario/poa/cadastrar-identificacao"
              columns={columnData}
              dropdown={{
                  view: { linkTo: "poa/visualizar" },
                  editar: { linkTo: "poa/editar-identificacao" },
                  orcamento: { linkTo: "poa/orcamento" },
                  dePara: { linkTo: "poa/depara" },
                  desativar: { linkTo: "poas" }
              }}
            />
        </Container>
    </>
  );
};

export default Poa;
