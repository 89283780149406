import React, { useEffect, useState } from "react";

import { Container } from "./styles";
import { Title } from "../../styles/global";
import MainTable from "../../components/MainTable";
import { formatarData } from "../../utils/formatDados";
import useJuridico from "../../actions/cruds/Juridico/useJuridico";
import { useAuth } from "../../hooks/auth";
import getPermission from "../../utils/getPermission";

const Juridico: React.FC = () => {
  const { processosJuridicos, getProcessosJuridicos } = useJuridico();
  const [searchValue, setSearchValue] = useState<string>("");
  const [perPage, setPerPage] = useState<number>()

  const {login} = useAuth();
  const juridicoPermission = getPermission(login,"Juridico") ?? '';

  const optionsSituacao = [
    { label: "Em andamento", value: "em_andamento", id: "002" },
    { label: "Concluído", value: "concluido", id: "003" },
  ];

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      getProcessosJuridicos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }]);
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handleItemsPerPage = (per_page: number) => {
    setPerPage(per_page);
    getProcessosJuridicos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: per_page }]);
  };

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getProcessosJuridicos([{ label: "busca_rapida", value: searchValue }, { label: "per", value: perPage }, { label: "page", value: processosJuridicos.pagination.current_page + page }]);
  };

  const organizeData = (data: any[]) => {
    const withoutData = <i>Sem dados</i>;
    if (typeof data === "object") {
      data = data.map((item) => {
        return {
          id: item?.id,
          data: formatarData(item?.data_inicio_processo, false) ?? withoutData,
          organizacao: item?.organization?.nome ?? withoutData,
          numero: item?.law_process?.codigo ?? withoutData,
          assunto: item?.assunto ?? withoutData,
          advogado: item?.user?.nome ?? withoutData,
          local: item?.local_processo ?? withoutData,
          situacao: item.situacao ? optionsSituacao.find(o => o.value == item?.situacao)?.label : withoutData,
          ativo: item.ativo,
        };
      });
    }
    return data;
  };

  const columnData = [
    { headerName: "ID", field: "id" },
    { headerName: "Data", field: "data" },
    { headerName: "Organização", field: "organizacao" },
    { headerName: "Número do processo", field: "numero" },
    { headerName: "Assunto", field: "assunto" },
    { headerName: "Advogado", field: "advogado" },
    { headerName: "Local do Processo", field: "local" },
    { headerName: "Situação", field: "situacao" },
    { headerName: "Status", field: "ativo" },
  ];

  return (
    <>
      <Title color="#333">
        <span>Gestão de Processo jurídico</span>
      </Title>
      <Container color="#FFF">
        <MainTable
          columns={columnData}
          data={organizeData(processosJuridicos.data)}
          pagination={processosJuridicos.pagination}
          permission={juridicoPermission}
          hideCreate={juridicoPermission == "Visitante"}
          isMaxWidthCell
          handleItemsPerPage={handleItemsPerPage}
          handlePagination={handlePagination}
          onSearchChange={setSearchValue}
          registerLink="/juridico/cadastrar"
          dropdown={{
            view: { linkTo: "/juridico/visualizar" },
            editar: { linkTo: "/juridico/editar" },
            historicoProcessoJudicial: { linkTo: "/juridico/historicoprocesso" },
            finalizarProcessoJudicial: { linkTo: "/juridico/finalizarprocesso" },
            desativar: { linkTo: "judicial_processes" },
          }}
        />
      </Container>
    </>
  );
};

export default Juridico;
