import { useEffect, useState } from "react";
import Header from "../../../components/Header";
import { CSidebar } from "../../../components/Sidebar";
import { Container, Title } from "./style";
import MainTable from "../../../components/MainTable";
import { formatarData, pontuarCpf } from "../../../utils/formatDados";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import getPermission from "../../../utils/getPermission";

export function Empregados() {
  const [usuarios, setUsuarios] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const {login} = useAuth();
  const rhPermission = getPermission(login,"Recursos Humanos") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`users?busca_rapida=${searchValue}`)
        .then((response) => {
          setUsuarios(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(`users?page=${usuarios.pagination.current_page + page}`)
      .then((response) => {
        setUsuarios(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "Nome", field: "nome" },
    { headerName: "CPF", field: "cpf" },
    { headerName: "Data de Admissão", field: "data_admissao" },
    { headerName: "Cargo", field: "cargo" },
    { headerName: "Tipo do Funcionário", field: "tipo_funcionario" },
    { headerName: "Local de Trabalho", field: "local_trabalho" },
    { headerName: "Situação", field: "ativo" },
  ];

  const organizeData = (data: any) => {
    const withoutData = <i>Sem dados</i>;

    if (typeof data === "object") {
      data = data.map((d) => ({
        id: d.id,
        nome: d.nome,
        cpf: pontuarCpf(d.cpf),
        data_admissao: d.data_admissao
          ? formatarData(d.data_admissao, false)
          : withoutData,
        cargo: d.cargo_atual ? d.cargo_atual.nome : withoutData,
        tipo_funcionario: d.employee_type ? d.employee_type.nome : withoutData,
        local_trabalho: d.local_trabalho ? d.local_trabalho.nome : withoutData,
        ativo: d.ativo,
      }));
    }
    return data;
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <CSidebar />
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Header />
        <Title color="#333">
          <span>Gestão de empregados</span>
        </Title>
        <Container color="#FFF">
          <MainTable
            onSearchChange={setSearchValue}
            data={organizeData(usuarios.data)}
            pagination={usuarios.pagination}
            permission={rhPermission}
            hideCreate={rhPermission == "Visitante"}
            handlePagination={handlePagination}
            hideFilter={true}
            registerLink="/rh/empregados/cadastrar"
            columns={columnData}
            dropdown={{
              view: { linkTo: "/rh/empregados/visualizar" },
              editar: { linkTo: "/rh/empregados/editar" },
              documentos: { linkTo: "/rh/empregados/documentos" },
              ferias: { linkTo: "/rh/empregados/ferias" },
              desativar: { linkTo: "users" },
            }}
          />
        </Container>
      </div>
    </div>
  );
}
