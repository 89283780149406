import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  ButtonAdd,
  ContainerButton2,
  Form,
  Input,
  SelectInput,
  TextTitle,
  Textarea,
  Title,
} from "../../../styles/global";
import Panel from "../../../components/Panel";
import Radio from "../../../components/InputRadio";
import { Button, Container } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import useOrgao from "../../../actions/cruds/useOrgao";
import useDespesas from "../../../actions/cruds/useDespesas";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { FormHandles } from "@unform/core";
import useProtocolo from "../../../actions/cruds/Protocolo/useProtocolo";
import organizeDataSelect from "../../../utils/organizeDataSelect";
import api from "../../../services/api";
import useSolicitanteExterno from "../../../actions/cruds/Protocolo/useSolicitanteExterno";
import useOrigemDemanda from "../../../actions/cruds/Protocolo/useOrigemDemanda";
import InputFilev2 from "../../../components/InputFilev2";
import SecondaryTable from "../../../components/SecondaryTable";
import { GridColDef } from "@mui/x-data-grid";
import useComite from "../../../actions/cruds/useComite";
import useInstanciaProtocolo from "../../../actions/cruds/Protocolo/useInstanciaProtocolo";
import { SaveWithProgress } from "../../../components/SaveCircularIntegration";
import { useAuth } from "../../../hooks/auth";
import getPermission from "../../../utils/getPermission";


const demanda_options = [
  { label: "Interno", value: "interno" },
  { label: "Externo", value: "externo" },
];

const rateio_options = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

const situacao_options = [
  { label: "Não iniciado", value: "nao_iniciado", id: "001" },
  { label: "Em andamento", value: "em_andamento", id: "002" },
  { label: "Concluído", value: "concluido", id: "003" },
];

const ProtocoloCreate: React.FC = () => {
  const { orgaos, getOrganizations } = useOrgao();
  const { despesas, getDespesas } = useDespesas();
  const { getSolicitantesExternos, solicitantesExternos } = useSolicitanteExterno();
  const { getTiposEncaminhamento, tiposEncaminhamento, getSolicitantesProtocolo, solicitantesProtocolo, createProtocolo } = useProtocolo();
  const { getOrigensDemanda, origensDemanda } = useOrigemDemanda();
  const { comites, getComittees } = useComite();
  const { instanciasProtocolo, getInstanciasProtocolo } = useInstanciaProtocolo();

  const { login } = useAuth();
  const protocoloPermission = getPermission(login,"Protocolo");
  const isVisitante = protocoloPermission == "Visitante";

  const [estimativaDatas, setEstimativaDatas] = useState({
    dataInicio: "",
    dataFim: "",
  });
  const [valueLocal, setValueLocal] = useState<Object>();
  const [solicitante, setSolicitante] = useState<Object>();
  const [origemSolicitante, setOrigemSolicitante] = useState<String>();
  const [comite, setComite] = useState<Object>();
  const [usuariosByCargo, setUsuariosByCargo] = useState([]);
  const [solicitanteComitesFront, setSolicitanteComitesFront] = useState<Array<Object>>([]);
  const [solicitanteComitesBack, setSolicitanteComitesBack] = useState<Array<Object>>([]);
  const [salvando, setSalvando] = useState<boolean>(false);

  const getUsuariosByCargo = (nome?: string) => {
    api
      .get(`users?cargo_gerente_assessor=true&nome=${nome}&ativo=true`)
      .then((response) => setUsuariosByCargo(response.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getOrganizations([{ label: 'ativo', value: true }]);
    getDespesas([{ label: 'ativo', value: true }]);
    getTiposEncaminhamento([{ label: 'ativo', value: true }]);
    getOrigensDemanda([{ label: 'ativo', value: true }]);
    getSolicitantesExternos([{ label: 'ativo', value: true }]);
    getComittees([{ label: 'ativo', value: true }]);
    getSolicitantesProtocolo([{ label: 'ativo', value: true }]);
    getInstanciasProtocolo([{ label: 'ativo', value: true }]);
    getUsuariosByCargo("");
    formRef.current?.setFieldValue("data_entrada", new Date().toISOString().split('T')[0])

  }, []);

  const organizeDataSolicitanteExterno = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.id,
          label: d.nome,
          telefone: d.telefone,
          email: d.email,
          local: d.local
        }));
        return data;
      }
    }
    return [];
  }

  const organizeDataComCodigo = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.id,
          label: `${d.codigo} - ${d.nome}`,
        }));
        return data;
      }
    }
    return [];
  }

  const organizeDataSolicitante = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.id,
          label: `${d.nome} - ${d.tabela == 'representatives' ? "Representante" : "Funcionário"}`,
          nome: d.nome,
          tabela: d.tabela,
        }));
        return data;
      }
    }
    return [];
  };

  const organizeDataComittee = (data) => {
    if (typeof data === 'object') {
      if (Object.keys(data).length > 0) {
        data = data.map(d => ({
          value: d.id,
          label: d.nome,
        }));
        return data;
      }
    }
    return [];
  };

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: object) => {
      try {
        setSalvando(true);

        if (valueLocal == 'interno') {
          if (origemSolicitante == 'representatives') {
            data["representative_id"] = solicitante?.value;
          } else if (origemSolicitante == 'users') {
            data["solicitante_id"] = solicitante?.value;
          }

          if (solicitanteComitesBack.length == 0) {
            alert("Adicione Comitês ao Solicitante!");
            return;
          }
          data["demand_comittees_attributes"] = solicitanteComitesBack;
        };

        const documentFile = formRef.current?.getFieldValue("nome_arquivo");
        if (!documentFile) {
          alert("Selecione o arquivo do documento!");
          return;
        };

        const dataEstimativaConclusao = formRef.current?.getFieldValue("data_previsao_conclusao");
        const dataArquivo = formRef.current?.getFieldValue("data_arquivo");
        if (dataArquivo > dataEstimativaConclusao) {
          alert("A data de estimativa de conclusão deve ser posterior a data do arquivo!");
          formRef.current?.setFieldValue("data_arquivo", "");
          formRef.current?.setFieldValue("data_previsao_conclusao", "");
          setEstimativaDatas({
            ...estimativaDatas,
            dataInicio: "",
            dataFim: "",
          });
          return;
        };

        console.log("data", data);
        await createProtocolo(data);
      } catch (err) {

      } finally {
        setSalvando(false);
      }
    }, [solicitante, origemSolicitante, solicitanteComitesBack]
  );

  const differenceDate = () => {
    if (estimativaDatas.dataInicio === "" || estimativaDatas.dataFim === "") {
      return "Preencha ambas as datas para calcular a estimativa";
    }

    const dataInicio = new Date(estimativaDatas.dataInicio);
    const dataFim = new Date(estimativaDatas.dataFim);

    if (dataFim < dataInicio) {
      return "Data de Conclusão anterior à Data de Início"
    }

    const diferencaEmMilissegundos = Math.abs(
      dataFim.getTime() - dataInicio.getTime()
    );

    const differenceDate = Math.floor(
      diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
    );

    return `${differenceDate} dias`;
  };

  const handleChangeLocal = (localSelected) => {
    setValueLocal(localSelected.target.value);
    formRef.current?.setFieldValue("solicitante", "");
    formRef.current?.setFieldValue("comittee", "");
    formRef.current?.setFieldValue("external_requester_id", "");
    setSolicitanteComitesBack([]);
    setSolicitanteComitesFront([]);
  };

  const handleAdicionarComite = () => {
    if (!solicitante || !comite) {
      alert("Selecione o Solicitante/Comitê");
      return;
    }
    if (solicitanteComitesFront?.find((o) => o.id == comite?.value)) {
      alert("Comitê já adicionado");
      return;
    };

    setSolicitanteComitesFront([...solicitanteComitesFront,
    { id: comite?.value, solicitante: solicitante?.nome, origem: solicitante?.tabela == 'representatives' ? "Representante" : "Funcionário", comite: comite?.label }]);
    setSolicitanteComitesBack([...solicitanteComitesBack,
    { comittee_id: comite?.value, comiteLabel: comite?.label }]);

  };

  const dataTitleSolicitanteComite: GridColDef[] = [
    { field: "solicitante", headerName: "Solicitante", flex: 2 },
    { field: "origem", headerName: "Origem", flex: 1 },
    { field: "comite", headerName: "Comitê", flex: 2 },
    {
      field: "action",
      headerName: "Ações",
      description: "Ações por coluna.",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant="text"
            onClick={(e) => onDeleteSolicitanteComite(e, params.row)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash-can" />
          </Button>
        );
      },
    },
  ];

  function onDeleteSolicitanteComite(e: any, row: any): void {
    setSolicitanteComitesFront(solicitanteComitesFront?.filter((el) => el.comite !== row.comite));
    setSolicitanteComitesBack(solicitanteComitesBack?.filter((el) => el.comiteLabel !== row.comite));
  };

  return (

    <div style={{margin: "0px", padding: "0px"}}>

      {
        isVisitante ? <>Você não tem permissão para acessar esse módulo.</> :
        
    <Container>
      <Form id="formProtocolo" ref={formRef} onSubmit={handleSubmit}>
        <Title color="#333">
          <span>Cadastrar Protocolo</span>
        </Title>

        <Panel titulo="Identificação">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Data de cadastro:</TextTitle>
              <Input
                name="data_entrada"
                type="date"
                disabled
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Selecione de onde está vindo essa demanda:</TextTitle>
              <Radio name="local" options={demanda_options} onChange={handleChangeLocal} required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Organização:</TextTitle>
              <SelectInput
                name="organization_id"
                placeholder="Selecione..."
                options={organizeDataComCodigo(orgaos.data)}
                onInputChange={(e) => {
                  getOrganizations([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Despesa:</TextTitle>
              <SelectInput
                name="expense_type_id"
                placeholder="Selecione..."
                options={organizeDataComCodigo(despesas.data)}
                onInputChange={(e) => {
                  getDespesas([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                }}
                required
              />
            </Grid>
            {/* <Grid xs={12}>
              <TextTitle>A demanda será um rateio?</TextTitle>
              <Radio name="rateio" options={rateio_options} required />
            </Grid> */}
            <Grid xs={12}>
              <TextTitle>Instância:</TextTitle>
              <SelectInput
                name="protocol_instance_id"
                placeholder="Selecione..."
                options={organizeDataSelect(instanciasProtocolo.data, "id", "nome")}
                onInputChange={e => { getInstanciasProtocolo([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
                required
              />
            </Grid>
            {valueLocal == 'interno' ?
              <>
                <Grid xs={12}>
                  <TextTitle>Solicitante:</TextTitle>
                  <SelectInput
                    name="solicitante"
                    placeholder="Selecione..."
                    options={organizeDataSolicitante(solicitantesProtocolo)}
                    onInputChange={(e) => {
                      getSolicitantesProtocolo([{ label: "nome", value: e }, { label: 'ativo', value: true }]);
                    }}
                    onChange={(solicitante) => {
                      setSolicitante(solicitante);
                      setOrigemSolicitante(solicitante.tabela);
                      formRef.current?.clearField("comittee");
                    }}
                    required
                    isDisabled={solicitanteComitesFront?.length > 0}
                  />
                </Grid>
                <Grid xs={8}>
                  <TextTitle>Comitê:</TextTitle>
                  <SelectInput
                    name="comittee"
                    placeholder="Selecione..."
                    options={organizeDataComittee(comites.data)}
                    onChange={(comite) => {
                      setComite(comite);
                    }}
                  />
                </Grid>
                <Grid xs={4}>
                  <ButtonAdd style={{ marginTop: "28px" }} type="button" onClick={handleAdicionarComite}>
                    <FontAwesomeIcon icon={faPlus} /> Adicionar
                  </ButtonAdd>
                </Grid>
                {solicitanteComitesFront?.length > 0 && (
                  <Grid xs={12}>
                    <SecondaryTable
                      columsData={dataTitleSolicitanteComite}
                      rowsData={solicitanteComitesFront}
                      getRowId={(row) => row.comite}
                    />
                  </Grid>
                )}
                {/* <Grid xs={12}>
                  <TextTitle>Instância:</TextTitle>
                  <SelectInput
                  name="instance_id"
                  placeholder="Selecione..."
                  options={valueComite ? organizeDataInstanciaRepresentantes(competenciasInstituicaoByComite.data) : []}
                  onChange={(e) => setValueInstitutionCompetence(e)}
                  required
                  />
                </Grid> */}
              </>
              : valueLocal == 'externo' ?
                <Grid xs={12}>
                  <TextTitle>Solicitante:</TextTitle>
                  <SelectInput
                    name="external_requester_id"
                    placeholder="Selecione..."
                    options={organizeDataSolicitanteExterno(solicitantesExternos.data)}
                    onInputChange={e => { getSolicitantesExternos([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
                    required
                  />
                </Grid>
                : null}
          </Grid>
        </Panel>

        <Panel titulo="Documento">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Nome do documento:</TextTitle>
              <Input name="titulo_arquivo" type="text" required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data do documento:</TextTitle>
              <Input name="data_arquivo" type="date"
                onChange={(dataDocumento) =>
                  setEstimativaDatas({
                    ...estimativaDatas,
                    dataInicio: dataDocumento.target.value,
                  })}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Documento: <span>*</span></TextTitle>
              <InputFilev2 name="nome_arquivo" required />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Demanda">
          <Grid container spacing={6}>
            <Grid xs={6}>
              <TextTitle>Origem demanda:</TextTitle>
              <SelectInput
                name="demand_origin_id"
                placeholder="Selecione..."
                options={organizeDataSelect(origensDemanda.data, "id", "nome")}
                onInputChange={e => { getOrigensDemanda([{ label: 'nome', value: e }, { label: 'ativo', value: true }]) }}
                required
              />
            </Grid>
            <Grid xs={12}>
              <TextTitle>Demanda:</TextTitle>
              <Input name="demanda" required />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Tipo de encaminhamento:</TextTitle>
              <SelectInput
                name="forwarding_type_id"
                placeholder="Selecione..."
                options={organizeDataComCodigo(tiposEncaminhamento.data)}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Situação:</TextTitle>
              <SelectInput
                name="situacao"
                placeholder="Selecione..."
                options={situacao_options}
                defaultValue={{ label: "Não iniciado", value: "nao_iniciado", id: "001" }}
                isDisabled
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Responsável pela demanda:</TextTitle>
              <SelectInput
                name="user_id"
                placeholder="Selecione..."
                options={organizeDataSelect(usuariosByCargo.data, "id", "nome")}
                onInputChange={(e) => {
                  getUsuariosByCargo(e);
                }}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Data estimada de conclusão:</TextTitle>
              <Input
                name="data_previsao_conclusao"
                type="date"
                onChange={(dataEstimativa) =>
                  setEstimativaDatas({
                    ...estimativaDatas,
                    dataFim: dataEstimativa.target.value,
                  })}
                required
              />
            </Grid>
            <Grid xs={6}>
              <TextTitle>Estimativa em dias:</TextTitle>
              <Input disabled name="estimativa_dias" value={differenceDate()} />
            </Grid>
          </Grid>
        </Panel>

        <Panel titulo="Observação">
          <Grid container spacing={6}>
            <Grid xs={12}>
              <TextTitle>Observação:</TextTitle>
              <Textarea name="observacao" />
            </Grid>
          </Grid>
        </Panel>
      </Form>

      <ContainerButton2>
        <Link to="/protocolo">
          <button className="button_return">
            <FontAwesomeIcon icon={faX} size="1x" /> Cancelar
          </button>
        </Link>
        <SaveWithProgress disabled={salvando} form={"formProtocolo"}></SaveWithProgress>
      </ContainerButton2>
    </Container>
      }
    </div>
  );
};

export default ProtocoloCreate;
