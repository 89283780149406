import { useEffect } from "react";
import useComite from "../../../../actions/cruds/useComite";
import MainTable from "../../../../components/MainTable";
import { Title } from "../../../../styles/global";
import { Container } from "./styles";
import { formatarData } from "../../../../utils/formatDados";
import { useAuth } from "../../../../hooks/auth";
import getPermission from "../../../../utils/getPermission";

export function Comites() {

  const { comites, getComittees } = useComite();

  useEffect(() => {
    getComittees();
  }, [])

  const {login} = useAuth();
  const orçamentarioPermission = getPermission(login,"Orçamentário") ?? '';

  const organizeData = (data: any) => {

    if (typeof data === 'object') {
      data = data.map(d => ({
        id: d.id,
        sigla: d.sigla,
        nome: d.nome,
        codigo: d.codigo,
        contrato: d.management_contract.nome,
        organization: d.management_contract.organization.nome.nome,
        data_assinatura: formatarData(d.data_assinatura, false),
        ativo: d.ativo
      }));
    }
    return data;
  }

  const columnData = [
    { headerName: "Código", field: "codigo" },
    { headerName: "Sigla", field: "sigla" },
    { headerName: "Comitê", field: "nome" },
    { headerName: "Contrato de gestão", field: "contrato" },
    { headerName: "Organização", field: "organization" },
    { headerName: "Data de assinatura", field: "data_assinatura" },
    { headerName: "Status", field: "ativo" }
  ];

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    getComittees([{ label: "page", value: comites.pagination.current_page + page }]);
  };

  return (
    <>
      <Title color='#333'>
        <span>
          Gestão de comitê
        </span>
      </Title>
      <Container color='#FFF'>
        <MainTable
          data={organizeData(comites.data)}
          pagination={comites.pagination}
          permission={orçamentarioPermission}
          hideCreate={orçamentarioPermission == "Visitante"}
          handlePagination={handlePagination}
          hideFilter={true}
          registerLink="/orcamentario/cadastros/comites/cadastrar"
          columns={columnData}
          dropdown={{
            editar: { linkTo: "/orcamentario/cadastros/comites/editar" },
            desativar: { linkTo: "comittees", desactiveText: "Desativar" },
          }}
        />
      </Container>
    </>
  )
}