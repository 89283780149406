import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useAuth } from '../hooks/auth';
import { useToast } from '../hooks/toast';

const configs = {
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': 'https://api-siga.sigaaguas.org.br',
    'Vary': 'Accept-Encoding',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Max-Age': '0'
};

const api = axios.create({
    baseURL: 'https://api-siga.sigaaguas.org.br',
    // baseURL: 'https://api-siga.sigaaguas.org.br'
    // DevURL: 'https://api-dev-codex.sigaaguas.org.br'
    // DevFrontURL: 'https://dev-codex.sigaaguas.org.br/'
    // headers: configs
});

// api.interceptors.response.use( (response: AxiosResponse) => {
//         // Qualquer código de status dentro do intervalo 2xx faz com que esta função seja acionada
//         return response.data;
//     },
//     (err: AxiosError) => {
//         // Qualqueer códigos de status que estejam fora do intervalo 2xx fazem com que esta função seja acionada
//         const status = err.response?.status || 500;
//         // erros globais aqui
//         switch (status) {
//             // autenticação (problemas relacionados ao token)
//             case 401: {
//                 return;
//             }

//             // proibido (questões relacionadas à permissão) | futuramente erros de perfis
//             case 403: {
//                 return;
//             }

//             // bad request
//             case 400: {
//                 return;
//             }

//             // not found
//             case 404: {
//                 return;
//             }

//             // conflito
//             case 409: {
//                 return;
//             }

//             // não processável | Quando cai em uma regra de negocio
//             case 422: {
//                 return Promise.reject(
                    
//                 );
//             }

//             // erro genérico de API (relacionado ao servidor) inesperado
//             default: {
//                 return;
//             }
//         }
//     }
// );

const AxiosInterceptor = ({ children }) => {
    const [isSet, setIsSet] = useState(false); //https://dev.to/arianhamdi/react-hooks-in-axios-interceptors-3e1h#comment-1pm76

    const {addToast} = useToast();
    const {signOut} = useAuth();

    useEffect(() => {

        const resInterceptor = (response: AxiosResponse) => {
            // Qualquer código de status dentro do intervalo 2xx faz com que esta função seja acionada
            return response;
        }

        const errInterceptor = (error: AxiosError) => {

            // Qualqueer códigos de status que estejam fora do intervalo 2xx fazem com que esta função seja acionada
            const status = error.response?.status || 500;
            // erros globais aqui
            switch (status) {
                // autenticação (problemas relacionados ao token)
                case 401: {
                    signOut();
                    return Promise.reject(error);
                }

                // proibido (questões relacionadas à permissão) | futuramente erros de perfis
                case 403: {
                   return Promise.reject(error);
                }

                // bad request
                case 400: {
                   return Promise.reject(error);
                }

                // not found
                case 404: {
                    addToast({
                        type: 'error',
                        title: 'Recursos não encontrados!',
                        description: 'Entre em contato com os desenvolvedores.',
                    });
                   return Promise.reject(error);
                }

                // conflito
                case 409: {
                   return Promise.reject(error);
                }

                // não processável | Quando cai em uma regra de negocio
                case 422: {
                    const {data} = error.response;

                    const dataError = Object.entries(data);
              
                    addToast({
                      type: 'error',
                      title: 'Ação não executada!',
                      description: `${dataError[0][0]}: ${dataError[0][1][0]}`,
                    });

                    return Promise.reject(error);
                }

                // erro genérico de API (relacionado ao servidor) inesperado
                default: {
                    addToast({
                        type: 'error',
                        title: 'Erro interno do servidor!',
                        description: 'Entre em contato com os desenvolvedores back-end.',
                    });

                    return Promise.reject(error);
                }
            }
        }


        const interceptor = api.interceptors.response.use(resInterceptor, errInterceptor);
        setIsSet(true);

        return () => api.interceptors.response.eject(interceptor);

    }, [isSet, addToast]);

    return isSet && children;
}

export default api;
export { AxiosInterceptor }