import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { CSidebar } from "../../components/Sidebar";
import { Container, Title } from "./styles";
import MainTable from "../../components/MainTable";
import { formatarData } from "../../utils/formatDados";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import getPermission from "../../utils/getPermission";

export function Representantes() {
  const [searchValue, setSearchValue] = useState("");
  const [representantesInstituicao, setRepresentantesInstituicao] = useState(
    []
  );

  const {login} = useAuth();
  const membrosPermission = getPermission(login,"Membros") ?? '';

  useEffect(() => {
    const delayedSearch = setTimeout(() => {
      api
        .get(`representatives?busca_rapida=${searchValue}`)
        .then((response) => {
          setRepresentantesInstituicao(response.data);
        })
        .catch((error) => console.log(error));
    }, 500);

    return () => clearTimeout(delayedSearch);
  }, [searchValue]);

  const handlePagination = (goBack = false) => {
    const page = goBack ? -1 : 1;

    api
      .get(
        `representatives?page=${
          representantesInstituicao.pagination.current_page + page
        }`
      )
      .then((response) => {
        setRepresentantesInstituicao(response.data);
      })
      .catch((error) => console.log(error));
  };

  const columnData = [
    { headerName: "Código", field: "id" },
    { headerName: "CPF", field: "cpf" },
    { headerName: "Nome", field: "nome" },
    { headerName: "Data de Cadastro", field: "created_at" },
    { headerName: "Situação", field: "ativo" },
  ];

  const withoutData = <i>Sem dados</i>;

  const organizeData = (data: any) => {
    if (typeof data === "object") {
      data = data.map((d: any) => ({
        id: d.id,
        cpf: d.cpf,
        nome: d.nome,
        created_at: d.created_at
          ? formatarData(d.created_at, false)
          : withoutData,
        ativo: d.ativo,
      }));
    }

    return data;
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ height: "100%" }}>
        <CSidebar />
      </div>
      <div style={{ display: "block", width: "100%" }}>
        <Header />
        <Title color="#333">
          <span>Gestão de membros - Representantes</span>
        </Title>
        <Container color="#FFF">
          <MainTable
            onSearchChange={setSearchValue}
            data={organizeData(representantesInstituicao.data)}
            pagination={representantesInstituicao.pagination}
            permission={membrosPermission}
            hideCreate={membrosPermission == "Visitante"}
            handlePagination={handlePagination}
            hideFilter={true}
            isMaxWidthCell
            registerLink="/membros/representantes/cadastrar"
            columns={columnData}
            dropdown={{
              view: { linkTo: "/membros/representantes/visualizar" },
              editar: { linkTo: "/membros/representantes/editar" },
              desativar: { linkTo: "representatives" },
            }}
          />
        </Container>
      </div>
    </div>
  );
}
