import React, { useEffect, useState } from "react";
import DropDownMainTable from "../DropdownMainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { IMainTableProps } from "./types";

import {
  faMagnifyingGlass,
  faPlus,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  PaginationContainer,
  SearchContainer,
  StyledDataCell,
  StyledHeaderCell,
  StyledTable,
} from "./styles";

const MainTable: React.FC<IMainTableProps> = ({
  data,
  pagination,
  columns,
  dropdown,
  registerLink,
  isMaxWidthCell,
  handlePagination,
  handleItemsPerPage,
  onSearchChange,
  hideCreate,
  permission
}) => {
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    console.log("📦 MainTable:", data);
  }, []);

  const renderCellValue = (value: any) => {
    if (typeof value === "number") {
      return value.toString();
    } else if (typeof value === "boolean") {
      return value ? "Ativo" : "Inativo";
    } else {
      return value;
    }
  };

  // Ordenação
  const sortedData = data
    ? [...data].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortOrder === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
      }
    })
    : [];

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  // Pesquisa
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    console.log(newValue);
    onSearchChange
      ? onSearchChange(newValue)
      : console.warn(
        "📦 MainTable: onSearchChange deve ser passado pelas props."
      );
  };

  return (
    <>
      <SearchContainer>
        <div className="input_container">
          <input placeholder="Pesquisar..." onChange={handleSearchChange} />
          <button>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </button>
        </div>

        {!hideCreate && (
          <div className="container_option">
            <Link
              to={registerLink || ""}
              className="button_option"
              style={{ backgroundColor: "#14B851", textDecoration: "none" }}
            >
              <FontAwesomeIcon
                style={{ marginRight: 6 }}
                icon={faPlus}
                size="1x"
              />
              Cadastrar
            </Link>
          </div>
        )}
      </SearchContainer>

      <StyledTable>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <StyledHeaderCell
                key={index}
                onClick={() => handleSort(column.field)}
                className={
                  sortColumn === column.field ? `sorted-${sortOrder}` : ""
                }
              >
                {column.headerName}
              </StyledHeaderCell>
            ))}
            <StyledHeaderCell>Ações</StyledHeaderCell>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <StyledDataCell key={colIndex} isMaxWidthCell={isMaxWidthCell}>
                  {renderCellValue(row[column.field])}
                </StyledDataCell>
              ))}
              <StyledDataCell>
                <DropDownMainTable
                  view={{
                    linkTo: `${dropdown.view?.linkTo}/${row.id}`,
                    visible: !!dropdown.view,
                  }}
                  editar={{
                    linkTo: `${dropdown.editar?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.editar && !!row.ativo && row.situacao != "Concluído",
                  }}
                  viewInstrumento={{
                    openModal: dropdown.viewInstrumento?.openModal,
                    updateId: dropdown.viewInstrumento?.updateId,
                    id: row.id,
                    tipo: dropdown.viewInstrumento?.tipo,
                    visible: !!dropdown.viewInstrumento && !!row.ativo,
                  }}
                  editarInstrumento={{
                    openModal: dropdown.editarInstrumento?.openModal,
                    updateId: dropdown.editarInstrumento?.updateId,
                    id: row.id,
                    tipo: dropdown.editarInstrumento?.tipo,
                    visible: permission != "Visitante" && !!dropdown.editarInstrumento && !!row.ativo,
                  }}
                  certidoes={{
                    linkTo: `${dropdown.certidoes?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.certidoes && !!row.ativo,
                  }}
                  documentos={{
                    linkTo: `${dropdown.documentos?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.documentos && !!row.ativo,
                  }}
                  orcamento={{
                    linkTo: `${dropdown.orcamento?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.orcamento,
                  }}
                  codigoNup={{
                    openModal: dropdown.codigoNup?.openModal,
                    updateId: dropdown.codigoNup?.updateId,
                    id: row.id,
                    visible: permission != "Visitante" && !!dropdown.codigoNup && !!row.ativo && row.situacao != "Concluído",
                  }}
                  comprovante={{
                    linkTo: `${dropdown.comprovante?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.comprovante && !!row.ativo && row.situacao != "Concluído",
                  }}
                  linha={{
                    linkTo: `${dropdown.linha?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.linha && !!row.ativo,
                  }}
                  numeroProcesso={{
                    openModal: dropdown.numeroProcesso?.openModal,
                    updateId: dropdown.numeroProcesso?.updateId,
                    id: row.id,
                    visible: permission != "Visitante" && !!dropdown.numeroProcesso && !!row.ativo && row.situacao != "Concluído",
                  }}
                  planejamento={{
                    linkTo: `${dropdown.planejamento?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.planejamento && !!row.ativo && row.situacao != "Concluído",
                  }}
                  acaoPlanejamento={{
                    linkTo: `${dropdown.acaoPlanejamento?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.acaoPlanejamento && !!row.ativo && row.situacao != "Concluído",
                  }}
                  atualizarAcaoPlanejamento={{
                    linkTo: `${dropdown.atualizarAcaoPlanejamento?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.atualizarAcaoPlanejamento,
                  }}
                  normaInterna={{
                    linkTo: `${dropdown.normaInterna?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.normaInterna && !!row.ativo && row.situacao != "Concluído",
                  }}
                  vincularProtocolo={{
                    linkTo: `${dropdown.vincularProtocolo?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.vincularProtocolo && !!row.ativo && row.situacao != "Concluído",
                  }}
                  historicoProcessoJudicial={{
                    linkTo: `${dropdown.historicoProcessoJudicial?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.historicoProcessoJudicial && !!row.ativo && row.situacao != "Concluído",
                  }}
                  finalizarProcessoJudicial={{
                    linkTo: `${dropdown.finalizarProcessoJudicial?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.finalizarProcessoJudicial && !!row.ativo && row.situacao != "Concluído",
                  }}
                  realizarPagamentoDespesa={{
                    linkTo: `${dropdown.realizarPagamentoDespesa?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.realizarPagamentoDespesa && !!row.ativo && row.situacao == 'Aguardando realização do pagamento',
                  }}
                  informarPagamentoDespesa={{
                    linkTo: `${dropdown.informarPagamentoDespesa?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.informarPagamentoDespesa && !!row.ativo && row.situacao == 'Aguardando confirmação do pagamento',
                  }}
                  validarDotacao={{
                    linkTo: `${dropdown.validarDotacao?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.validarDotacao && !!row.ativo && row.situacao != 'Concluído',
                  }}
                  ferias={{
                    linkTo: `${dropdown.ferias?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.ferias && !!row.ativo && (row.tipo_funcionario == 'Estagiário' || row.tipo_funcionario == 'Empregado' || row.tipo_funcionario == 'Jovem aprendiz'),
                  }}
                  confirmarFerias={{
                    linkTo: `${dropdown.confirmarFerias?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.confirmarFerias && !!row.ativo && row.situacao != "Concluído",
                  }}
                  aditivo={{
                    linkTo: `${dropdown.aditivo?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.aditivo && !!row.ativo,
                  }}
                  apostilamento={{
                    linkTo: `${dropdown.apostilamento?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.apostilamento && !!row.ativo,
                  }}
                  dePara={{
                    linkTo: `${dropdown.dePara?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.dePara && !!row.ativo,
                  }}
                  notaFiscal={{
                    linkTo: `${dropdown.notaFiscal?.linkTo}/${row.id}`,
                    visible: permission != "Visitante" && !!dropdown.notaFiscal && !!row.ativo,
                  }}
                  excluir={{
                    id: row.id,
                    linkTo: dropdown.excluir?.linkTo,
                    visible: permission == "Administrador" && !!dropdown.excluir,
                  }}
                  desativar={{
                    id: row.id,
                    status: row.ativo,
                    linkTo: dropdown.desativar?.linkTo,
                    desactiveText: dropdown.desativar?.desactiveText,
                    visible: permission == "Administrador" && !!dropdown.desativar,
                  }}
                  cancelar={{
                    id: row.id,
                    situacao: row.situacao,
                    linkTo: dropdown.cancelar?.linkTo,
                    activateSituacao: dropdown.cancelar?.activateSituacao,
                    visible: permission == "Administrador" && !!dropdown.cancelar,
                  }}
                />
              </StyledDataCell>
            </tr>
          ))}
        </tbody>
      </StyledTable>

      <PaginationContainer>
        <div>
          <span>Itens por página:</span>

          {!handleItemsPerPage ? (
            <b>25</b>
          ) : (
            <div>
              {[25, 50, 100, 200].map((value) => (
                <button
                  key={value}
                  disabled={pagination?.per_page === value}
                  onClick={() => handleItemsPerPage(value)}
                >
                  {value}
                </button>
              ))}
            </div>
          )}
        </div>
        <div>
          <span>
            Página {pagination?.current_page} de {pagination?.total_pages}
          </span>
          <button
            onClick={() => handlePagination(true)}
            disabled={pagination?.current_page === 1}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            onClick={() => handlePagination()}
            disabled={pagination?.current_page === pagination?.total_pages}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </PaginationContainer>
    </>
  );
};

export default MainTable;
